import React from "react";
import styled from "@emotion/styled";
import Image from "./Image";
import EnterCursor from "./EnterCursor";
import { mobile } from "../styles/global";
import { css, Global } from "@emotion/react";
import { AnimatePresence } from "framer-motion";
import SplashLogo from "./SplashLogo";
import { useSiteState } from "../context/siteContext";
import useBreakpoint from "../utils/useBreakpoint";
// import { isMobile } from "detect-touch-device";

const Splash = ({ hero, fullHeight }) => {
	const [siteState, setSiteState] = useSiteState();
	const heroFG =
		hero?.heroImage?.asset?.metadata?.palette?._rawDominant?.title ||
		"var(--white)";
	const heroBG =
		hero?.heroImage?.asset?.metadata?.palette?._rawDominant?.background;

	return (
		<>
			<Global
				styles={css`
					:root {
						--splash-fg: ${heroFG};
						--splash-bg: ${heroBG};
					}
				`}
			/>
			<Wrap
				css={css`
					height: 100vh;
					pointer-events: ${siteState.entered ? "none" : "auto"};
					${mobile} {
						height: ${fullHeight};
					}
				`}
			>
				<SplashLogo
					css={css`
						width: ${!siteState.entered ? "50%" : "253px"};
						height: ${siteState.entered && "12px"};
						opacity: ${siteState.entered ? 0 : 1};
						position: fixed;
						top: 18px;
						left: 0;
						padding-inline: var(--padding);
						z-index: 100;
						transition: width 500ms var(--splash-ease), opacity 0s linear 750ms;

						${mobile} {
							width: ${!siteState.entered ? "100%" : "253px"};
						}

						svg path {
							transition: fill 300ms var(--splash-ease);
							fill: ${siteState.entered ? "var(--fg)" : heroFG};
						}
					`}
				/>
				<SplashInner
					css={css`
						background-color: var(--splash-bg);
						transform: ${siteState.entered && `translateY(-100%)`};
						opacity: ${siteState.entered && `0`};
					`}
				>
					<AnimatePresence>
						<EnterCursor
							initial={{ opacity: 1 }}
							enter={{ opacity: 1 }}
							exit={{ opacity: 0 }}
						/>
					</AnimatePresence>
					<SplashMedia>
						{hero.mediaType === "video" ? (
							<SplashVideo
								autoPlay={true}
								muted={true}
								loop
								playsInline
								poster={hero?.heroImage?.url}
							>
								<source src={hero?.heroVideo?.asset?.url} />
							</SplashVideo>
						) : (
							<SplashImage image={hero.heroImage} cover={true} />
						)}
					</SplashMedia>
					<SplashText
						css={css`
							color: ${hero?.heroImage?.asset?.metadata?.palette?._rawDominant
								.title || "var(--white)"};
							opacity: ${siteState.textEntered ? 0 : 1};
						`}
					>
						{hero.timestamp && (
							<p
								css={css`
									text-transform: uppercase;
								`}
							>
								{hero.timestamp}
							</p>
						)}
						<p>{hero?.subtitle || hero?.featureProject?.name}</p>
					</SplashText>
				</SplashInner>
			</Wrap>
		</>
	);
};

const Wrap = styled.button`
	width: 100vw;
	position: absolute;
	inset: 0;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	cursor: none;
	z-index: 100;
	/* padding: var(--padding-sml) var(--padding); */
`;

const SplashInner = styled.div`
	height: 100%;
	transition: transform 1000ms cubic-bezier(0.215, 0.61, 0.355, 1),
		opacity 0ms linear 1000ms;
`;

const SplashMedia = styled.div`
	height: 100%;
`;

const SplashVideo = styled.video`
	height: 100vh;
	width: 100vw;
	object-fit: cover;
`;

const SplashImage = styled(Image)`
	/* position: absolute;
	inset: 0; */
	width: 100%;
	height: 100%;
	/* z-index: -1; */

	img {
		object-fit: cover !important;
	}
`;

const SplashText = styled.div`
	position: absolute;
	padding: var(--padding-sml) var(--padding);
	bottom: 0;
	left: 0;
	transition: opacity 300ms ease-in-out 350ms;
`;

export default Splash;
