import React, { useState, useEffect, useRef } from "react";
import * as logoData from "../utils/logoData.json";
import styled from "@emotion/styled";
import lottie from "lottie-web";
import { useLocation } from "@reach/router";
import { mobile, mobileUp } from "../styles/global";
import { Link } from "gatsby";
import { useSiteState } from "../context/siteContext";

const SplashLogo = ({ className }) => {
	const containerRef = useRef();
	const [animation, setAnimation] = useState();
	const location = useLocation();
	// const [location, setLocation] = useState();
	const [siteState, setSiteState] = useSiteState();

	useEffect(() => {
		if (containerRef?.current) {
			const anim = lottie.loadAnimation({
				container: containerRef.current,
				renderer: "svg",
				loop: false,
				autoplay: false,
				animationData: logoData,
			});
			setAnimation(anim);
		}
	}, [containerRef]);

	const endframe = 69;
	const startframe = 36;

	useEffect(() => {
		if (siteState.textEntered && animation) {
			animation.play();
		}
	}, [animation, siteState.textEntered]);

	return (
		<Wrap className={className}>
			<div ref={containerRef}></div>
		</Wrap>
	);
};

const Wrap = styled.div`
	/* svg {
		fill: var(--fg);

		* {
			fill: var(--fg);
		}
	} */

	a,
	a * {
		&:hover {
			opacity: 1 !important;
		}
	}

	${mobile} {
		z-index: 5;
		// width: 270px;

		div {
			width: fit-content;
			// height: 27px;
		}
	}
`;

export default SplashLogo;
