import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useRef, useEffect, useState } from "react";
import { mobile, mobileUp } from "../styles/global";
import { useSiteState } from "../context/siteContext";

const EnterCursor = () => {
	const [top, setTop] = useState(null);
	const [left, setLeft] = useState(null);
	const [siteState, setSiteState] = useSiteState();

	const mouseMoveHandler = (e) => {
		setTop(e.pageY);
		setLeft(e.pageX);
	};

	useEffect(() => {
		window.addEventListener("mousemove", mouseMoveHandler);
	}, []);

	return (
		<CursorWrap
			css={css`
				color: var(--white);
				pointer-events: none;
				cursor: none;
				z-index: 101;
				/* font-family: var(--hermes); */
				/* font-size: var(--accent-size);
				line-height: var(--accent-line);
				letter-spacing: var(--accent-letter); */
				position: fixed;
				padding: 19px var(--margin);
				top: ${top}px;
				left: ${left}px;
				display: ${siteState.entered && "none"};
				mix-blend-mode: exclusion;

				@media (hover: hover) {
					top: ${top}px;
					left: ${left}px;
				}

				@media (hover: none) {
					display: none;
				}

				${mobile} {
					display: none;
				}
			`}
		>
			Enter
		</CursorWrap>
	);
};

const CursorWrap = styled.span``;

export default EnterCursor;
