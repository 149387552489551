import * as React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import Seo from "../components/Seo";
import Section from "../components/Section";
import BlockText from "../components/BlockText";
import { useState } from "react";
import { useSiteState } from "../context/siteContext";
import { Link } from "gatsby";
import { mobile, mobileUp } from "../styles/global";
// import { Logo } from "../components/Svg";
import useSiteSettings from "../utils/useSiteSettings";
import useBreakpoint from "../utils/useBreakpoint";
import useWindow from "../utils/useWindow";
import { useEffect } from "react";
import Splash from "../components/Splash";
import ProjectsContent from "../components/ProjectsContent";

const IndexPage = ({ data }) => {
	const { page } = data;
	const { acknowledgement } = useSiteSettings();
	const { isMobile } = useBreakpoint();
	const w = useWindow();
	// const { ref, inView, entry } = useInView({ threshold: 0 });
	const [scrolled, setScrolled] = useState(false);
	const [siteState, setSiteState] = useSiteState();

	// const [entered, setEntered] = useState(false);
	// const [textEntered, setTextEntered] = useState(false);

	const [fullHeight, setFullHeight] = useState();
	const allProjects = data?.allSanityProject?.projects.filter(
		(p) => p.indexHeroImage.length !== 0
	);

	useEffect(() => {
		setFullHeight(w.innerHeight + "px");
	}, [w]);

	useEffect(() => {
		const enter = () => {
			setSiteState((prevSiteState) => ({
				...prevSiteState,
				textEntered: true,
			}));
			setTimeout(() => {
				setSiteState((prevSiteState) => ({
					...prevSiteState,
					entered: true,
				}));
			}, 1250);
		};

		window.addEventListener("touchend", enter);
		window.addEventListener("mouseup", enter);
	}, []);

	// const heroAspect =
	// 	page.hero[0].heroImage.asset.width / page.hero[0].heroImage.asset.height;

	// const feedIsOdd = page.feed.length % 2 === 1;

	// const splashMotion = {
	// 	exit: {
	// 		gridColumn: "1/-1",
	// 		opacity: 0,
	// 		height: 0,
	// 		padding: 0,
	// 		margin: "0 calc(-1 * var(--margin))",
	// 		// display: "none",
	// 		transition: {
	// 			duration: 0.25,
	// 		},
	// 	},
	// 	enter: {
	// 		display: "flex",
	// 		height: "fit-content",
	// 		opacity: 1,
	// 		paddingTop: "26px",
	// 		paddingBottom: "36px",
	// 		transition: {
	// 			type: "tween",
	// 			delayChildren: 0.5,
	// 			staggerChildren: 0.2,
	// 		},
	// 	},
	// };

	const breakpointColumnsObj = {
		default: 3,
		768: 2,
	};

	const enteredNotMobile = !siteState.entered && !isMobile;

	return (
		<>
			<Seo
				title={"Home"}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<Global
				styles={css`
					html,
					body {
						height: ${!siteState.entered ? "100vh" : "auto"};
						overflow-y: ${!siteState.entered ? "hidden" : "auto"};
					}
					:root {
						--header-bg: ${!siteState.entered ? "transparent" : "var(--white)"};
						--opacity: ${!siteState.entered ? "1" : "0"};
					}
				`}
			/>
			<Splash hero={page?.hero[0]} fullHeight={fullHeight} />
			<ProjectsContent allProjects={allProjects} isHome={true} />
			<Acknowledgement full={true}>
				<div>
					<BlockText value={acknowledgement} />
				</div>
			</Acknowledgement>
		</>
	);
};

const AllProjects = styled(Section)`
	padding-bottom: var(--padding-xl);

	${mobile} {
		padding-top: var(--margin);
		padding-bottom: var(--padding-xl);
	}
`;

const ProjectsLink = styled(Link)`
	width: fit-content;
`;

const HomeFeed = styled.div`
	padding: 0 var(--margin);
`;

const FeedHeader = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	// grid-column: 1/-1;
	padding-bottom: var(--padding-xl);
	padding-top: 8px;

	${mobileUp} {
		border-top: var(--border-black);
	}

	${mobile} {
		padding-top: var(--padding-sml);
	}
`;

const FeedBody = styled(Section)`
	padding: 0 var(--padding-lrg) var(--padding-xl) var(--padding-lrg);
	column-gap: var(--padding-lrg);
	row-gap: var(--padding-lrg);
	font-family: var(--hermes);
	font-size: var(--accent-size);
	line-height: var(--accent-line);
	letter-spacing: var(--accent-letter);

	${mobile} {
		padding: 0;
		padding-bottom: var(--padding-lrg);
		grid-template-columns: repeat(2, 1fr);
	}

	> :last-child {
		${mobileUp} {
			grid-column: ${(props) => props.odd && "2 / 4"};
		}
	}
`;

const Acknowledgement = styled(Section)`
	${mobileUp} {
		div {
			padding-top: 8px;
			border-top: var(--border-black);
			padding-bottom: var(--padding-xl);
			p {
				width: 85%;
			}
		}
	}

	${mobile} {
		text-align: center;
		background-color: var(--beige);
		padding: var(--padding-lrg) var(--padding-med) var(--padding-xl);
	}
`;

export const query = graphql`
	query HomeQuery {
		page: sanityHomePage {
			title
			hero {
				mediaType
				heroImage {
					asset {
						_id
						width
						height
						url
						metadata {
							palette {
								_rawDominant
							}
						}
					}
					alt
				}
				heroVideo {
					asset {
						url
					}
				}
				timestamp
				subtitle
				featureProject {
					name
					subtitle
					location
					slug {
						current
					}
				}
			}
			feed {
				... on SanityArchiveItem {
					slug {
						current
					}
					id
					headline
					images {
						asset {
							_id
							width
							height
							url
							metadata {
								palette {
									_rawDominant
								}
							}
						}
						alt
					}
					_type
				}
				... on SanityProject {
					slug {
						current
					}
					id
					name
					hero {
						heroImage {
							asset {
								_id
								width
								height
								url
								metadata {
									palette {
										_rawDominant
									}
								}
							}
							alt
						}
					}
					content {
						images {
							... on SanityImageWithCaption {
								_key
								_type
								alt
								caption
								asset {
									gatsbyImageData
									_id
									width
									height
									url
									metadata {
										dimensions {
											aspectRatio
										}
										palette {
											_rawDominant
										}
									}
								}
							}
						}
					}
					_type
				}
			}
		}
		allSanityProject(sort: { order: ASC, fields: orderRank }) {
			projects: nodes {
				_id
				slug {
					current
				}
				hero {
					heroImage {
						asset {
							_id
							width
							height
							url
							metadata {
								palette {
									_rawDominant
								}
							}
						}
					}
				}
				indexHeroImage {
					asset {
						_id
						width
						height
						url
						metadata {
							palette {
								_rawDominant
							}
						}
					}
				}
				name
				type
				content {
					slideType
					images {
						... on SanityImageWithCaption {
							_key
							_type
							alt
							caption
							asset {
								gatsbyImageData
								_id
								width
								height
								url
								metadata {
									dimensions {
										aspectRatio
									}
									palette {
										_rawDominant
									}
								}
							}
						}
					}
					images2 {
						... on SanityImageWithCaption {
							_key
							_type
							alt
							caption
							asset {
								gatsbyImageData
								_id
								width
								height
								url
								metadata {
									dimensions {
										aspectRatio
									}
									palette {
										_rawDominant
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default IndexPage;
